// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import theme from '../theme';

import Image from './image';
import MainContainer from './main-container';
import SectionTitle from './section-title';


const StyledImageFlexSection = styled.section`
	position: relative;
	overflow: hidden;
	line-height: 1.6rem;
`;

const StyledContent = styled.div`
    font-size: .8125rem;
    padding-right: 80px;
    padding: 40px 0;
`;

const ImageWrapper = styled.div`

`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 80px 0;

	@media screen and (max-width: ${props => props.breakPoint}) {
        display: block;

		${StyledContent} {
			padding: 40px 0;
			width: 100% !important;
		}

		${ImageWrapper} {
			position: relative !important;
			top: initial;
			bottom: initial;
			right: initial;
			width: 100% !important;
			min-height: 100px;
			margin-bottom: 20px;

			.gatsby-image-wrapper {
				position: relative !important;
				transform: none !important;
				left: 0 !important;
			}
		}
	}
`;

class ImageFlexSection extends Component {

    render () {
		const { title, content } = this.props;
		const imageWidthPercent = this.props.imageWidthPercent || 50;
		const breakPoint = this.props.breakPoint
			? this.props.breakPoint + 'px'
			: theme.breakpoints.md;
		return (
			<StyledImageFlexSection
				style={{
					backgroundColor: this.props.color
				}}
				className="clearfix"
			>
				<MainContainer>
					<Wrapper breakPoint={breakPoint}>
						<StyledContent style={{
							width: 100 - imageWidthPercent + '%',
							color: this.props.textColor,
						}}>
							<SectionTitle
								title={title}
								color={this.props.textColor}
								subtitle={this.props.subtitle}
							/>
							<div>
								{content}
							</div>
						</StyledContent>
						<ImageWrapper
							style={{
								width: 100 - imageWidthPercent + '%'
							}}
						>
                            {this.props.fixedImage && (
                                <Image fixed={this.props.fixedImage} />
                            )}
                            {this.props.fluidImage && (
                                <Image image={this.props.fluidImage} />
                            )}
						</ImageWrapper>
					</Wrapper>
				</MainContainer>
			</StyledImageFlexSection>
		);
	}

}

export default ImageFlexSection
